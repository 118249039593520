/* eslint-disable */
<template>
  <div class="pagination-wrapper">
    <div class="pagination">
      <button
        class="pagination__btn"
        :disabled="pagination && pagination.page === 1"
        @click="onSelectPage(pagination.page - 1)"
      >
        <svg-icon class-name="pagination-icon" icon-name="pagination-prev" />
      </button>

      <button
        v-for="btn in visiblePages"
        class="pagination__btn"
        v-bind:class="{ active: pagination && pagination.page === btn }"
        @click="onSelectPage(btn)"
        :key="btn"
      >
        <span>{{ btn }}</span>
      </button>

      <button
        class="pagination__btn more"
        v-if="pagination && pagination.pages > lastBtn"
        @click="onSelectPage(lastBtn + 1)"
      >
        <span>...</span>
      </button>

      <button
        class="pagination__btn"
        v-if="pagination && pagination.pages > 1"
        :disabled="pagination.page === pagination.pages"
        @click="onSelectPage(pagination.page + 1)"
      >
        <svg-icon class-name="pagination-icon" icon-name="pagination-next" />
      </button>
    </div>
    <p class="pagination-label" v-if="withLabel">
      {{showItemsLabel}}/{{pagination.total}}
    </p>
  </div>

</template>

<script>
const showPagesLength = 6

const fillPages = (from, to) => {
  if (from > to) {
    return []
  }
  const output = []
  let page = from
  while (page <= to) {
    output.push(page)
    page += 1
  }
  return output
}

const getVisiblePages = (currentPage, pageLength) => {
  if (pageLength <= showPagesLength) {
    return fillPages(1, pageLength)
  }

  const half = showPagesLength / 2

  if (currentPage <= half) {
    return fillPages(1, showPagesLength)
  } else {
    const from = currentPage - (showPagesLength / 2)
    const lastPage = currentPage + (showPagesLength / 2)
    const to = lastPage <= pageLength ? lastPage : pageLength

    return fillPages(from, to)
  }
}

// Todo: возможно стоит подключить к v-model и генерировать только событие @change с отправкой измененного объекта <pagination>

export default {
  name: 'ListPagination',
  props: {
    pagination: Object,
    withLabel: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onSelectPage (pageIndex) {
      this.$emit('onSelectPage', pageIndex)
    },
  },
  computed: {
    visiblePages () {
      return this.pagination ? getVisiblePages(this.pagination.page, this.pagination.pages) : null
    },
    currentPage () {
      return this.pagination.page
    },
    lastBtn () {
      return this.visiblePages[this.visiblePages.length - 1]
    },
    showItemsLabel () {
      let from = (this.pagination.page - 1) * this.pagination.per_page
      let to = this.pagination.page * this.pagination.per_page
      if (to > this.pagination.total) {
        to = to - (to - this.pagination.total)
      }

      return `${from}-${to}`
    },
  },
}
</script>

<style lang="scss" scoped>
$gutter: 20px;

.pagination {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;

  &-wrapper {
    @include flex(row, flex-start, center);
  }

  &__btn {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 8px;
    background-color: $color-grey;
    color: $text-gray;
    transition: .4s ease all;
    border: none;

    .pagination-icon {
      stroke: $text-gray
    }

    &.more {
      border-color: transparent;
      padding: 0;
      &:hover {
        color: $text-gray;
        background: transparent;
      }
    }

    &:hover {
      color: #fff;
      background-color: $color-primary-hover;
      .pagination-icon {
        stroke: #fff
      }
    }

    &[disabled] {
      cursor: not-allowed;

      &:hover {
        cursor: not-allowed;
        background-color: $color-grey;
      }
    }

    &.active {
      color: #fff;
      background-color: $color-primary;
    }

    &:not(:last-child) {
      margin-right: 10px;
    }
  }

  &-icon {
    fill: transparent;
    stroke: $primary-color;
  }

  &-label {
    color: $text-gray;
    font-size: 14px;
    padding-left: $gutter;
  }
}
</style>
